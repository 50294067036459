import {usePiniaStore} from "@/store";
import {handleErrors} from "@/utils/errors";
import {loadConfig, switchTheme} from "@/utils/helpers";
import {fetchBackend} from "./utils/network";
import * as Sentry from "@sentry/browser";

let authenticated = true;

const initUser = (id:number, role:string, name:string) => {
    const store = usePiniaStore();

    // set user and role to vuex store
    store.user = {
        id: id,
        role: role,
        name: name,
    };

    Sentry.setUser({
        id: (store.user.id || store.user.name!).toString(),
        username: store.user.name!.toString(),
        role: store.user.role
    });

    // disabled as it does not work with vite in build mode ATM
    // if(store.user.role === "PER") {
    //     switchTheme("generic.light");
    // } else {
    //     switchTheme("generic.light.compact");
    // }
};

export default {
    async authenticated(): Promise<boolean | undefined> {

        const store = usePiniaStore();

        // reload roles if they are unset in vuex store, usually happens on page refresh
        // should be async to wait for backend response
        if (authenticated && !store.user.role) {

            // reload config first
            await loadConfig();

            const response = await fetchBackend("/index/role");
            if (response.status != 200) {
                // eslint-disable-next-line require-atomic-updates
                authenticated = false;
                return authenticated;
            }
            const result = await response.json();
            if (result.success) {
                initUser(result.roles[0].personal_id, result.roles[0].id, result.roles[0].value);
                // eslint-disable-next-line require-atomic-updates
                authenticated = true;
                return authenticated;
            }

        } else {
            return authenticated;
        }


    },
    async logIn(login: string, password: string, totp: string, cf_token: string): Promise<boolean> {
        try {
            const requestBody = new URLSearchParams({
                loginUsername: login,
                loginPassword: password,
                loginTotp: totp,
                loginCFToken: cf_token,
            });

            const response = await fetchBackend("/user/login", {
                method: 'POST',
                body: requestBody,
            });

            const result = await handleErrors(response);
            if (result.success) {
                authenticated = true;
                const userRole = result.roles[0];
                initUser(userRole.personal_id, userRole.id, userRole.value);
                return true;
            } else {
                return false;
            }
        } catch (e) {
            return false;
        }
    },
    async logOut(): Promise<void> {
        if (!authenticated) {
            return Promise.resolve();
        }

        await fetchBackend("/user/logout");
        authenticated = false;
        const store = usePiniaStore();
        store.user = {
            id: null,
            role: null,
            name: null
        };
        Sentry.setUser(null);
    }
};
